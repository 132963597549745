@import "../../../styles/helpers";

.title {
    margin-bottom: 20px;
    text-align: center; }

.info {
    max-width: 445px;
    margin: 0 auto 40px;
    text-align: center;
    color: $neutrals3;
    @include dark {
        color: $neutrals6; } }

.nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -12px -6px 80px;
    @include d {
        margin-bottom: 64px; }
    @include m {
        display: none; } }

.btn {
    margin: 12px 6px 0;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals2;
            color: $neutrals8; } } }

.dropdown {
    display: none;
    @include m {
        display: block;
        margin-bottom: 48px; } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include m {
        margin: -24px -8px 0; } }

.box {
    flex: 0 0 calc(25% - 32px);
    width: calc(25% - 32px);
    margin: 32px 16px 0;
    @include t {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px); }
    @include m {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 24px 8px 0; } }
