@import "../../styles/helpers";

.section {
    text-align: center; }

.stage {
    margin-bottom: 16px; }

.title {
    max-width: 650px;
    margin: 0 auto 32px;
    @include t {
        max-width: 550px; } }

.text {
    max-width: 450px;
    margin: 0 auto 48px;
    color: $neutrals4; }

