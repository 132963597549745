@import "../../styles/helpers";

.container {
    text-align: center; }

.preview {
    margin-bottom: 66px;
    @include m {
        margin-bottom: 48px; }
    img {
        width: 545px;
        @include d {
            width: 450px; }
        @include a {
            width: 100%; } } }

.title {
    margin-bottom: 20px; }

.text {
    max-width: 455px;
    margin: 0 auto 80px;
    color: $neutrals4;
    @include d {
        margin-bottom: 64px; }
    @include m {
        margin-bottom: 48px; } }

.subscription {
    max-width: 355px;
    margin: 0 auto 16px; }

.note {
    @include caption-2;
    color: $neutrals5;
    @include m {
        max-width: 240px;
        margin: 0 auto; }
    a {
        font-weight: 600;
        color: $primary;
        transition: opacity .2s;
        &:hover {
            opacity: .8; } } }


