@import "../../../../styles/helpers";

.history {
    margin-bottom: 72px;
    @include d {
        margin-bottom: 64px; }
    @include m {
        margin-bottom: 32px; } }

.item {
    display: flex;
    border-radius: 20px;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    border: 1px solid $neutrals6;
    @include m {
        display: block;
        border-radius: 24px; }
    @include dark {
        border-color: $neutrals3;
        background: $neutrals2; } }

.preview,
.details {
    flex: 0 0 50%; }

.preview {
    min-height: 516px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 20px 0 0 20px;
    @include m {
        min-height: 232px;
        border-radius: 24px 24px 0 0; } }

.details {
    padding: 64px 96px;
    @include x {
        padding: 64px; }
    @include d {
        padding: 56px; }
    @include t {
        padding: 48px 32px; }
    @include m {
        padding: 40px 16px; } }

.status {
    margin-bottom: 20px;
    padding: 0 12px; }

.title {
    margin-bottom: 20px;
    @include sf-pro-display;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -.01em;
    @include t {
        font-size: 32px; }
    @include m {
        margin-bottom: 16px;
        font-size: 24px;
        letter-spacing: 0; } }

.content {
    margin-bottom: 20px;
    color: $neutrals4;
    @include m {
        margin-bottom: 16px; } }
