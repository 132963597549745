@import "../../../styles/helpers";

.wrapper {
    position: relative; }

.title {
    margin-bottom: 64px;
    @include m {
        margin-bottom: 24px;
        text-align: center; } }

.wrap {
    margin: 0 -16px; }

.card {
    height: 100%; }

.btns {
    margin-top: 64px;
    text-align: center;
    @include m {
        margin-top: 48px; } }
